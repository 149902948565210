/* eslint-disable react/jsx-no-useless-fragment */
import { Link } from '@chakra-ui/react';
import React from 'react';

interface Props {
  link?: string;
  external?: boolean;
  children: React.ReactNode;
}

const LinkWrapper: React.FC<Props> = ({ link, external = true, children }) => (
  <>
    {link && link !== '' ? (
      <Link
        textDecoration="none"
        href={link}
        target={external ? '_blank' : '_self'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        {children}
      </Link>
    ) : (
      children
    )}
  </>
);

export default LinkWrapper;
