import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Heading,
  Img,
  Box,
} from '@chakra-ui/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

interface IMediaCarouselModalProps {
  isOpen: boolean;
  onClose: () => void;
  images: any[];
}

const PrevArrow = (props: any) => {
  const { style, onClick } = props;
  return (
    <Box
      position="relative"
      zIndex="100"
      left="-45px"
      bottom="-240px"
      boxSize="40px"
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      cursor="pointer"
      borderRadius="50%"
    >
      <AiOutlineArrowLeft size="30px" />
    </Box>
  );
};

const NextArrow = (props: any) => {
  const { style, onClick } = props;
  return (
    <Box
      position="relative"
      zIndex="100"
      right="-990px"
      bottom={{ xs: '290px', sm: '310px' }}
      boxSize="40px"
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      cursor="pointer"
      borderRadius="50%"
    >
      <AiOutlineArrowRight size="30px" />
    </Box>
  );
};

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  appendDots: (dots: any) => (
    <Box
      backgroundColor="transparent"
      borderRadius="10px"
      padding="10px"
      zIndex="10"
      position="relative"
      margin-top="25px"
      top="-30px"
    >
      <ul style={{ margin: '0px' }}>{dots}</ul>
    </Box>
  ),
  dotsClass: 'slick-dots slick-homeTesti',
};

const EventHighlightsModal: React.FunctionComponent<
  IMediaCarouselModalProps
> = ({ isOpen, onClose, images }) => (
  <Modal onClose={onClose} isOpen={isOpen}>
    <ModalOverlay />
    <ModalContent
      maxW={{ xs: '95%', sm: '95%', md: '650px', lg: '900px', xl: '1100px' }}
      h={{ xs: '400px', sm: '500px', md: '550px', lg: '660px', xl: '700px' }}
    >
      <Heading variant="milestone" px="20px" pt="14px">
        Event Highlights:
      </Heading>
      <ModalCloseButton />
      <ModalBody
        px={{ xs: '20px', sm: '40px', md: '50px', lg: '60px' }}
        py={{ xs: '20px', md: '40px' }}
        pt={{ xs: '40px', md: '40px' }}
      >
        <Box
          w={{
            xs: '100%',
            sm: '100%',
            md: '550px',
            lg: '780px',
            xl: '980px',
          }}
          maxW="1000px"
          maxH={{ xs: '280px', sm: '300px', md: '400px', lg: '500px' }}
        >
          <Slider {...settings}>
            {images.map((item: any, id) => (
              <Box
                key={id}
                w={{
                  xs: '85%',
                  sm: '90%',
                  md: '500px',
                  lg: '700px',
                  xl: '1000px',
                }}
                h={{ xs: '300px', sm: '400px', md: '500px' }}
              >
                <Img src={item.location} alt={item._id} />
              </Box>
            ))}
          </Slider>
        </Box>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default EventHighlightsModal;
